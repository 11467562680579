import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from './helpers/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorUI from './components/global/ErrorUI';

import DashboardLayout from './components/layouts/Dashboard';

import ResourceState from './context/resource/resourceState';
import UserState from './context/user/userState';
import VaceState from './context/vace/vaceState';
import SocketState from './context/socket/socketState'

// URLS
const Homepage = React.lazy(() => import('./pages/Home'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const Register = React.lazy(() => import('./pages/auth/Register'));
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'));
const ActivateAccount = React.lazy(() => import('./pages/auth/ActivateAccount'));
const NetworkUI = React.lazy(() => import('./components/global/NetworkUI'));

const DashHome = React.lazy(() => import('./pages/dashboard/Dashboard'));
const KYBPage = React.lazy(() => import('./pages/dashboard/kyb/KYBPage'));

// Accounts
const Profile = React.lazy(() => import('./pages/dashboard/account/Profile'))
const Compliance = React.lazy(() => import('./pages/dashboard/account/Compliance'))
const Banks = React.lazy(() => import('./pages/dashboard/account/Banks'))
const Preferences = React.lazy(() => import('./pages/dashboard/account/Preferences'))
const Wallet = React.lazy(() => import('./pages/dashboard/wallet/Wallet'))

// Transactions
const Transactions = React.lazy(() => import('./pages/dashboard/transactions/Transactions'))
const TransactionDetails = React.lazy(() => import('./pages/dashboard/transactions/TransactionDetails'))

// Settlements
const Settlements = React.lazy(() => import('./pages/dashboard/settlements/Settlements'))
const SettlementDetails = React.lazy(() => import('./pages/dashboard/settlements/SettlementDetails'))

// Products
const Products = React.lazy(() => import('./pages/dashboard/products/Products'))
const ProductDetails = React.lazy(() => import('./pages/dashboard/products/ProductDetails'))
const AddProduct = React.lazy(() => import('./pages/dashboard/products/AddProduct'))

// Payments
const PaymentLinks = React.lazy(() => import('./pages/dashboard/payment-links/PaymentLinks'))
const PaymentLinkDetails = React.lazy(() => import('./pages/dashboard/payment-links/PaymentLinkDetails'))

const Subaccounts = React.lazy(() => import('./pages/dashboard/subaccounts/Subaccounts'))
const SubaccountDetails = React.lazy(() => import('./pages/dashboard/subaccounts/Subaccounts'))

// Settings
const Settings = React.lazy(() => import('./pages/dashboard/settings/Settings'))
const Security = React.lazy(() => import('./pages/dashboard/settings/Security'))
const WebhookAndAPIkey = React.lazy(() => import('./pages/dashboard/settings/WebhookKey'))

// Invoices
const Invoices = React.lazy(() => import('./pages/dashboard/invoices/Invoices'))
const InvoiceDetails = React.lazy(() => import('./pages/dashboard/invoices/InvoiceDetails'))
const InvoiceTransactions = React.lazy(() => import('./pages/dashboard/invoices/InvoiceTransactions'))
const NewInvoice = React.lazy(() => import('./pages/dashboard/invoices/NewInvoice'))

// checkout
const CheckoutPage = React.lazy(() => import('./pages/checkout/Pay'));
const CheckoutVerifyPage = React.lazy(() => import('./pages/checkout/Verify'));
const InvoicePreview = React.lazy(() => import('./pages/checkout/InvoicePreview'));

// fund account (i.e. wallet)
// const FundAccount = React.lazy(() => import('./pages/funding/FundAccount'));

const App = () => {

  const errorHandler = (err: any, info: any) => {
    console.log(err, 'logged error');
    console.log(info, 'logged error info');
  }

  return (

    <Router>

      <UserState>

        <SocketState>

            <VaceState>

              <ResourceState>

                <Suspense fallback={loader.MainLoader()}>

                  <ErrorBoundary FallbackComponent={ErrorUI} onReset={() => { window.location.reload() }} onError={errorHandler}>

                    <Routes>

                      {/* Auth Routes */}
                      <Route path='/home' element={<Login />} />
                      <Route path='/' element={<Login />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/register' element={<Register />} />
                      <Route path='/reset-password' element={<ResetPassword />} />
                      <Route path='/activate-account' element={<ActivateAccount />} />
                      <Route path='/no-network' element={<NetworkUI />} />

                      <Route path='/link/:label' element={<CheckoutPage />} />
                      {/* <Route path='/fund/wallet' element={<FundAccount />} /> */}
                      <Route path='/verify' element={<CheckoutVerifyPage />} />
                      <Route path='/invoice/:code' element={<InvoicePreview />} />

                      {/* Account Routes */}
                      <Route path='/dashboard' element={<DashboardLayout Component={DashHome} pageTitle="Dashboard" showBack={false} collapsed={false} />} />
                      <Route path='/dashboard/compliance' element={<DashboardLayout Component={KYBPage} pageTitle="Compliance" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/account/profile' element={<DashboardLayout Component={Profile} pageTitle="Profile" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/account/compliance' element={<DashboardLayout Component={Compliance} pageTitle="Compliance" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/account/banks' element={<DashboardLayout Component={Banks} pageTitle="All Banks" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/account/preferences' element={<DashboardLayout Component={Preferences} pageTitle="User Preferences" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settings' element={<DashboardLayout Component={Settings} pageTitle="General Settings" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settings/security' element={<DashboardLayout Component={Security} pageTitle="Security Settings" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settings/webhook-key' element={<DashboardLayout Component={WebhookAndAPIkey} pageTitle="Webhook and API Key" showBack={true} collapsed={false} />} />

                      {/* Wallet Routes */}
                      <Route path='/dashboard/wallet' element={<DashboardLayout Component={Wallet} pageTitle="Wallet" showBack={true} collapsed={false} />} />

                      {/* Transaction Routes */}
                      <Route path='/dashboard/transactions' element={<DashboardLayout Component={Transactions} pageTitle="Transactions" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/transactions/:id' element={<DashboardLayout Component={TransactionDetails} pageTitle="Transaction Details" showBack={true} collapsed={false} />} />

                      {/* Settlement Routes */}
                      <Route path='/dashboard/settlements' element={<DashboardLayout Component={Settlements} pageTitle="Settlements" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/settlements/:id' element={<DashboardLayout Component={SettlementDetails} pageTitle="Settlement Details" showBack={true} collapsed={false} />} />

                      {/* Product Routes */}
                      <Route path='/dashboard/products' element={<DashboardLayout Component={Products} pageTitle="Products" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/products/:id' element={<DashboardLayout Component={ProductDetails} pageTitle="Product Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/products/add' element={<DashboardLayout Component={AddProduct} pageTitle="Add Product" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/payment-links' element={<DashboardLayout Component={PaymentLinks} pageTitle="Payment Links" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/payment-links/:id' element={<DashboardLayout Component={PaymentLinkDetails} pageTitle="Payment Link Details" showBack={true} collapsed={false} />} />

                      <Route path='/dashboard/subaccounts' element={<DashboardLayout Component={Subaccounts} pageTitle="Subaccounts" showBack={true} collapsed={false} />} />
                      {/* <Route path='/dashboard/subaccounts/:id' element={<DashboardLayout Component={SubaccountDetails} pageTitle="Subaccounts Details" showBack={true} collapsed={false} />} /> */}

                      <Route path='/dashboard/invoices' element={<DashboardLayout Component={Invoices} pageTitle="Invoices" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/invoices/:id' element={<DashboardLayout Component={InvoiceDetails} pageTitle="Invoice Details" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/invoices/transactions/:id' element={<DashboardLayout Component={InvoiceTransactions} pageTitle="Invoice Transactions" showBack={true} collapsed={false} />} />
                      <Route path='/dashboard/invoices/new' element={<DashboardLayout Component={NewInvoice} pageTitle="New Invoice" showBack={true} collapsed={false} />} />

                    </Routes>

                  </ErrorBoundary>

                </Suspense>

              </ResourceState>

            </VaceState>

        </SocketState>

      </UserState>

    </Router>

  )

}

export default App;
