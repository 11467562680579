import React, { useReducer } from 'react'
import Cookies from 'universal-cookie';

import SocketContext from './socketContext';
import SocketReducer from './socketReducer'
import { io, Socket } from 'socket.io-client';

const SocketState = (props: any) => {

    const initialState = {
        socket: io(`${process.env.REACT_APP_SOCKET_IDENTITY_URL}`, { path: '/socket/', transports: ['websocket']}),
        vsocket: io(`${process.env.REACT_APP_SOCKET_CORE_URL}`, { path: '/socket/', transports: ['websocket']}),
        isConnect: false
    }

    const [state, dispatch] = useReducer(SocketReducer, initialState);

    return <SocketContext.Provider
        value={{
            socket: state.socket,
            vsocket: state.vsocket,
            isConnect: state.isConnect
        }}
    >
        {props.children}

    </SocketContext.Provider>
  
}

export default SocketState
